import React, { useState } from "react"
import styled from "styled-components"
import Input from "./input"

const FormContainer = styled.div`
  width: 90%;
  max-width: 600px;
`
const HTMLForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const FormStatus = styled.div`
  width: 100%;
  text-align: center;
  color: var(--color-second);
  padding: .3rem 0;
}
`

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })
  const [statusText, setStatusText] = useState("")

  const createEmptyForm = () => {
    return {
      name: "",
      email: "",
      message: "",
    }
  }

  const handleChange = event => {
    const key = event.target.name
    const updatedFormValue = event.target.value
    const newFormData = { ...formData, [key]: updatedFormValue }
    setFormData(newFormData)
  }

  const handleSubmit = event => {
    event.preventDefault()

    const form = event.target
    fetch(process.env.GATSBY_API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": form.getAttribute("name"),
        token: "DEYgCdrVD53MGVrX6ENwJVe4KWGhvmCt",
        ...formData,
      }).toString(),
    })
      .then(response => {
        if (!response.ok) throw Error(response.statusText)

        const emptyForm = createEmptyForm
        setFormData(emptyForm)
        setStatusText("Nachricht erfolgreich verschickt!")
      })
      .catch(error => setStatusText(`Error: ${error.message}`))
  }

  return (
    <FormContainer>
      <HTMLForm
        name="contact-form"
        id="contact-form"
        method="POST"
        onSubmit={e => handleSubmit(e)}
        action={process.env.GATSBY_API_URL}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="lastname" />
        <input type="hidden" name="form-name" value="contact-form" />
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={e => handleChange(e)}
          required
        />
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={e => handleChange(e)}
          required
        />
        <Input
          type="textarea"
          name="message"
          onChange={e => handleChange(e)}
          value={formData.message}
          required
        />
        <Input type="submit" name="submit" value="Anfrage Abschicken" />
      </HTMLForm>
      <FormStatus>{statusText}</FormStatus>
    </FormContainer>
  )
}

export default Form
