import React, { useContext, useCallback } from "react"
import { ContactContext } from "../context/contact-context"

import LetterSVG from "../svg/letter.svg"
import styled from "styled-components"

const LetterWrapper = styled.div`
  height: 40px;
  cursor: pointer;
  svg {
    height: 40px;
    path {
      fill: ${props => props.fill};
    }
  }
  svg:hover {
    path {
      fill: ${props => (props.hover ? props.hover : props.fill)};
    }
  }
`
const ContactLink = ({ fill, hover }) => {
  const { contactModalChangeHandler, contactModalOpen } = useContext(
    ContactContext
  )

  const handleClick = useCallback(() => {
    contactModalChangeHandler(!contactModalOpen)
  }, [contactModalOpen])

  const handleMouseEnter = useCallback(() => {
    document.body.style.background = "unset"
  }, [])
  const handleMouseLeave = useCallback(() => {
    document.body.style.background = "#fff"
  }, [])

  return (
    <LetterWrapper fill={fill} hover={hover}>
      <LetterSVG
        id="contact-link"
        onMouseDown={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </LetterWrapper>
  )
}

export default ContactLink
