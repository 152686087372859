import React, { useCallback } from "react"
import { motion, useScroll } from "framer-motion"
import styled from "styled-components"
import Breakpoints from "../utils/breakpoints"

import ArrowUp from "../svg/arrow-up.svg"

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  overflow: hidden;
  border-radius: 30px;
  @media (max-width: ${Breakpoints.mobile}px) {
    bottom: 80px;
  }
`
const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #e2e2e2;
  border: 2px #e2e2e2 solid;
  border-radius: 30px;
`
const Item = styled(motion.div)`
  width: 40px;
  height: 40px;
  background: white;
  transform-origin: 50% 100%;
`
const StyledArrowUp = styled(ArrowUp)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const TopButton = () => {
  const { scrollYProgress } = useScroll()
  //   const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2])

  const handleOnClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  return (
    <Wrapper className="top-button">
      <Container>
        <Item
          style={{
            scaleY: scrollYProgress,
          }}
        />
      </Container>
      <StyledArrowUp onClick={handleOnClick} />
    </Wrapper>
  )
}

export default TopButton
