import React, { useCallback } from "react"
import { Link } from "gatsby"
import Hover from "./hover"
import styled from "styled-components"
import Breakpoints from "../utils/breakpoints"

const MenuLink = ({ to, children, className }) => {
  const handleMouseEnter = useCallback(() => {
    document.body.style.background = "unset"
  }, [])
  const handleMouseLeave = useCallback(() => {
    document.body.style.background = "#fff"
  }, [])
  return (
    <Hover
      dataCursor={{
        animation: true,
        gooColor: "#38adae",
        gooSize: "50px",
        colorPointer: "#ffffff",
        blendMode: "color-dodge",
        overlay: true,
      }}
    >
      <Link
        to={to}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </Link>
    </Hover>
  )
}

const StyledMenuLink = styled(MenuLink)`
  color: #fff;
  @media (min-width: ${Breakpoints.desktop}px) {
    color: #38adae;
  }
  :hover {
    color: #c75251;
  }
`

export default StyledMenuLink
