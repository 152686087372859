import React, { createContext, useState } from "react";

export const MouseContext = createContext({
  cursorType: "",
  cursorData: {},
  cursorChangeHandler: () => {},
});

const MouseContextProvider = (props) => {
  const [cursorType, setCursorType] = useState("");
  const [cursorData, setCursorData] = useState({});

  const cursorChangeHandler = (cursorType,cursorData) => {
    setCursorType(cursorType);
    setCursorData(cursorData);
  };

  return (
    <MouseContext.Provider
      value={{
        cursorType: cursorType,
        cursorData: cursorData,
        cursorChangeHandler: cursorChangeHandler,
      }}
    >
      {props.children}
    </MouseContext.Provider>
  );
};

export default MouseContextProvider;
