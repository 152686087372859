import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
/* CSS Custom Properties Definitions */

:root {
  --color-main: #38adae;
  --color-second: #c75251;
  --color-text: #000000;

  --fontFamily-sans: Montserrat, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --fontFamily-serif: "Merriweather", "Georgia", Cambria, "Times New Roman",
    Times, serif;

  --liquid-ratio: 5/9;
  --liquid-width: 300px;
  --liquid-color: var(--color-main);
}

/* Focus */

:is(a, button, input, textarea, summary) {
  --outline-size: max(2px, 0.08em);
  --outline-style: solid;
  --outline-color: #fff;
}

:is(a, button, input, textarea, summary):focus {
  outline: var(--outline-size) var(--outline-style) var(--outline-color);
  outline-offset: var(--outline-offset, var(--outline-size));
}

:is(a, button, input, textarea, summary):focus-visible {
  outline: var(--outline-size) var(--outline-style) var(--outline-color);
  outline-offset: var(--outline-offset, var(--outline-size));
}

:is(a, button, input, textarea, summary):focus:not(:focus-visible) {
  outline: none;
}

/* HTML elements */

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-size: 18px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: var(--fontFamily-sans);
  color: var(--color-text);
  background: #fff;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  margin: 0;
}

h1::before {
  content: "";
  position: absolute;
  width: 34px;
  height: 5px;
  top: -10px;
  left: -15px;
  background: #000;
}

h1 {
  position: relative;
  margin: 1rem 0;
}


ul,
ol {
  list-style-position: outside;
  list-style-image: none;
  margin: 0;
}

main ol {
  padding: 0;
}

a {
  color: var(--color-main);
  word-break: break-word;
}
a:hover {
  color: var(--color-second);
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

.hyphen {
	hyphens: auto;
}

/* Custom classes */

.global-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 1rem;
}

main {
  flex: 1;
  /* margin-bottom: 15vh; */
  margin-bottom: 4rem;
}

/* Media queries */

@media (max-width: 42rem) {
  ul,
  ol {
    list-style-position: inside;
  }
}

/* Wordpress */
.wp-block-image.size-full {
	max-width: 600px;
	width: 100%;
}

`

export default GlobalStyle
