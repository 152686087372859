import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import MenuLink from "./menuLink"

import styled from "styled-components"
import ContactLink from "./contactLink"
import BurgerMenu from "./burgerMenu"

const TabletLinks = styled.ul`
  position: absolute;
  width: 100%;
  height: 0;
  padding: 0;
  visibility: hidden;
  height: ${props => (props.open ? "auto" : "0")};
  overflow-y: hidden;
  padding: ${props => (props.open ? "1rem" : "0")};
  visibility: ${props => (props.open ? "visible" : "hidden")};
  top: 120px;
  left: 0;
  background: var(--color-main);
  transition: all 0.5s ease-in-out;
  dislay: flex;
  gap: 0.5rem;
  flex-direction: column;
`

const TabletLink = styled.li`
  margin: 0 !important;
  text-align: center;
`

const TabletMenuContainer = styled.div`
  display: flex;
  gap: 3rem;
`

const TabletMenu = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allWpMenu {
        edges {
          node {
            id
            locations
            name
            menuItems {
              nodes {
                id
                label
                uri
              }
            }
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  const headerMenu = data.allWpMenu.edges.filter(function (edge) {
    return edge.node.locations.includes("GATSBY_HEADER_MENU")
  })[0]

  return (
    <TabletMenuContainer>
      <BurgerMenu
        fill="var(--color-main)"
        hover="var(--color-second)"
        setOpen={setOpen}
        open={open}
      />
      <TabletLinks
        key={headerMenu.node.id}
        id={headerMenu.node.name + "-tablet"}
        open={open}
      >
        {headerMenu.node.menuItems.nodes.map(node => {
          return (
            <TabletLink
              onClick={() => setOpen(false)}
              key={node.id}
              className="main-menu__item"
            >
              <MenuLink to={node.uri}>{node.label}</MenuLink>
            </TabletLink>
          )
        })}
        <TabletLink onClick={() => setOpen(false)}>
          <MenuLink to="/">Start</MenuLink>
        </TabletLink>
        <TabletLink onClick={() => setOpen(false)}>
          <MenuLink to="/blog">Blog</MenuLink>
        </TabletLink>
        <TabletLink onClick={() => setOpen(false)}>
          <MenuLink to="/referenzen">Referenzen</MenuLink>
        </TabletLink>
        <TabletLink onClick={() => setOpen(false)}>
          <MenuLink to="/workflow">Workflow</MenuLink>
        </TabletLink>
        <TabletLink onClick={() => setOpen(false)}>
          <MenuLink to="/kontakt">Kontakt</MenuLink>
        </TabletLink>
      </TabletLinks>
      <ContactLink fill="var(--color-main)" hover="var(--color-second)" />
    </TabletMenuContainer>
  )
}

export default TabletMenu
