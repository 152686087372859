import React, { useContext, useCallback } from "react"
import styled from "styled-components"
import { MouseContext } from "../context/mouse-context"

const InnerHover = styled.div``

const Hover = ({
  className,
  children,
  hoverEffect = "hovered",
  dataCursor = {},
  clickExit = false,
}) => {
  const { cursorChangeHandler } = useContext(MouseContext)

  const handleMouseEnter = useCallback(() => {
    cursorChangeHandler(hoverEffect, dataCursor)
  }, [cursorChangeHandler, hoverEffect, dataCursor])
  const handleMouseDown = useCallback(() => {
    clickExit && cursorChangeHandler("")
  }, [cursorChangeHandler, clickExit])
  const handleMouseLeave = useCallback(() => {
    cursorChangeHandler("")
  }, [cursorChangeHandler])

  return (
    <InnerHover
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </InnerHover>
  )
}

export default Hover
