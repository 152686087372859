import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"

import Logo from "../svg/julian_ismael_berger_logo.svg"
import Menu from "./menu"
import Hover from "./hover"
import MobileMenu from "./mobileMenu"
import TabletMenu from "./tabletMenu"

import Breakpoints from "../utils/breakpoints"

const HeaderContainer = styled.header``

const MenuContainer = styled.div`
  display: flex;
  padding: 2rem 0;
  flex: 0;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.mobile}px) {
    border-top: 4px solid #fff;
    position: fixed;
    background: var(--color-main);
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
    justify-content: space-around;
    align-items: center;
    z-index: 999;
  }
  ul {
    display: flex;
    list-style-type: none;
    margin-left: auto;
    margin: auto;
    margin-right: 0;
  }
  li {
    margin: auto 0;
    font-size: 1.2rem;
    font-weight: 700;
  }
  li:not(:last-child) {
    margin-right: 2rem;
  }
`

const HeaderLink = styled(Link)`
  font-weight: var(--fontWeight-bold);
  font-family: var(--font-heading);
  text-decoration: none;
  font-size: var(--fontSize-2);
  margin: auto 0;
  display: flex;
  svg {
    width: 200px;
  }
  :hover svg {
    fill: #fff;
    mix-blend-mode: difference;
  }
`

const MobileLogo = styled.div`
  display: flex;
  width: 100%;
  svg {
    width: 60%;
    margin: 1rem auto;
  }
`

const Header = ({ title }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.mobile}px)`,
  })
  const isTablet = useMediaQuery({
    query: `(min-width: ${+Breakpoints.mobile+1}px) and (max-width: ${+Breakpoints.desktop+1}px)`,
  })
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.desktop}px)`,
  })
  return (
    <HeaderContainer>
      {isMobile && (
        <Link to="/">
          <MobileLogo>
            <Logo title={title} />
          </MobileLogo>
        </Link>
      )}
      <MenuContainer isTablet={isTablet}>
        {(isDesktop || isTablet) && (
          <Hover
            dataCursor={{
              animation: true,
              gooColor: "#000000",
              gooSize: "120px",
              colorPointer: "#ffffff",
            }}
          >
            <HeaderLink to="/">
              <Logo title={title} />
            </HeaderLink>
          </Hover>
        )}
        {isDesktop && <Menu />}
        {isTablet && <TabletMenu />}
        {isMobile && <MobileMenu />}
      </MenuContainer>
    </HeaderContainer>
  )
}

export default Header
