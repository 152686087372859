import React from "react"
import styled from "styled-components"
import ContactForm from "./contactForm"
import { Link } from "gatsby"
import Breakpoints from "../utils/breakpoints"

const StyledFooter = styled.footer`
  background: #000000;
  color: #fff;
  padding: 1rem;
  @media (min-width: ${+Breakpoints.mobile+1}px) {
    padding: 2rem;
  }
  @media (max-width: ${Breakpoints.mobile}px) {
    margin-bottom: 70px;
  }
`

const CopyrightText = styled.div`
  text-align: center;
  padding-top: 2rem;
`

const getYear = () => new Date().getFullYear()

const Footer = () => {
  return (
    <StyledFooter>
      <ContactForm position="footer" />
      <CopyrightText>
        © Copyright {getYear()} Julian Ismael Berger{" "}|{" "}
        <Link to="/impressum">Impressum</Link>{" "}|{" "}
        <Link to="/datenschutz">Datenschutz</Link>
      </CopyrightText>
    </StyledFooter>
  )
}

export default Footer
