import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import MenuLink from "./menuLink"
import styled from "styled-components"

import Hover from "./hover"
import ContactLink from "./contactLink"

const DektopMenu = styled.ul``

const Menu = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allWpMenu {
        edges {
          node {
            id
            locations
            name
            menuItems {
              nodes {
                id
                label
                uri
              }
            }
          }
        }
      }
    }
  `)

  const headerMenu = data.allWpMenu.edges.filter(function (edge) {
    return edge.node.locations.includes("GATSBY_HEADER_MENU")
  })[0]

  return (
    <DektopMenu key={headerMenu.node.id} id={headerMenu.node.name}>
      {headerMenu.node.menuItems.nodes.map(node => {
        return (
          <li key={node.id} className="main-menu__item">
            <MenuLink to={node.uri}>{node.label}</MenuLink>
          </li>
        )
      })}
      <li>
        <MenuLink to="/blog">Blog</MenuLink>
      </li>
      <li>
        <MenuLink to="/referenzen">Referenzen</MenuLink>
      </li>
      <li>
        <MenuLink to="/workflow">Workflow</MenuLink>
      </li>
      <li>
        <MenuLink to="/kontakt">Kontakt</MenuLink>
      </li>
      <li>
        <Hover
          dataCursor={{
            animation: true,
            gooColor: "#38adae",
            gooSize: "50px",
            colorPointer: "#ffffff",
            blendMode: "color-dodge",
            overlay: true,
          }}
        >
          <ContactLink
            fill="var(--color-main)"
            hover="var(--color-second)"
          />
        </Hover>
      </li>
    </DektopMenu>
  )
}

export default Menu
