import React, { useContext } from "react"
import styled, { keyframes, css } from "styled-components"
import useMousePosition from "../hooks/useMousePosition"
import { MouseContext } from "../context/mouse-context"
import Breakpoints from "../utils/breakpoints"

import { useMediaQuery } from "react-responsive"

const hexToRGB = (hex, alpha = 1) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)
  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
}

const PointerContainer = styled.div``;

const pulseAnimation = color => keyframes`
  0% {
    box-shadow: 0 0 0 0px ${hexToRGB(color, 0.2)};
  }
  100% {
    box-shadow: 0 0 0 20px ${hexToRGB(color, 0)};
  }
`

const xAxisAnimation = () => keyframes`
0% {
  transform: translate(calc(-1*var(--rad)*1.6));
}
100% {
  transform: translate(calc(var(--rad)*1.6));
}
`

const yAxisAnimation = () => keyframes`
0% {
  transform: translateY(calc(var(--rad)*.7));
}
100% {
  transform: translateY(calc(-1*var(--rad)*1.1)) scale(0.8);
  /* background: #00fffb; */
}
`

const xAxisLateralAnimation = () => keyframes`
0% {
  transform: translate(calc(-1*var(--rad)*.6));
}
100% {
  transform: translate(calc(var(--rad)*.6));
}
`

const yAxisLateralAnimation = () => keyframes`
0% {
  transform: translateY(calc(var(--rad)/5));
}
100% {
  transform: translateY(calc(-1*var(--rad)));
}
`

const Dot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out,
    background-color 0.5s ease-in-out;
  border: 2px solid ${props => props.cursorData?.colorPointer};
  ${props =>
    props.cursorData?.animation &&
    css`
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: transparent;
      animation: ${pulseAnimation(props.cursorData?.colorPointer)} 2s infinite;
    `};
`

const WrapperGoo = styled.div`
  position: fixed;
  filter: url("#filter");
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
  pointer-events: none;
  left: 100px;
  top: 100px;
  // z-index: -1;
  z-index: ${props => (props.cursorData?.overlay ? "1" : "-1")};
  mix-blend-mode: ${props =>
    props.cursorData?.blendMode ? props.cursorData?.blendMode : "none"};
`
const Goo = styled.div`
  --rad: ${props =>
    props.cursorData?.gooSize ? props.cursorData?.gooSize : "50px"};
  width: var(--rad);
  height: var(--rad);
  position: absolute;
  top: calc(50% - var(--rad) / 2);
  left: calc(50% - var(--rad) / 2);
  :before {
    background: ${props =>
      props.cursorData?.gooColor ? props.cursorData?.gooColor : "#e2e2e2"};
    //transition: background 0.5s ease-in-out;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
  :nth-child(1) {
    transform: translate(calc(-1 * var(--rad) * 1.1));
    animation: ${xAxisLateralAnimation} 2s infinite alternate ease-in-out;
  }
  :nth-child(1):before {
    animation: ${yAxisLateralAnimation} 1s infinite 0.1s alternate ease-in-out;
  }
  :nth-child(2) {
    animation: ${xAxisAnimation} 2s infinite alternate ease-in-out;
  }
  :nth-child(2):before {
    animation: ${yAxisAnimation} 1s infinite 0.5s alternate ease-in-out;
  }
  :nth-child(3) {
    transform: translate(calc(var(--rad) * 1.1), calc(var(--rad) * 1.3));
    animation: ${xAxisLateralAnimation} 2s infinite alternate ease;
  }
  :nth-child(3):before {
    animation: ${yAxisLateralAnimation} 1s infinite 0.4s alternate ease-in-out;
  }
`

const StyledGoo = styled.svg`
  position: absolute;
  pointer-events: none;
`

const Pointer = () => {
  const isTabletOrDesktop = useMediaQuery({ query: `(min-width: ${+Breakpoints.mobile+1}px)` })
  const { cursorData } = useContext(MouseContext)
  const { x, y } = useMousePosition()
  return (
    <PointerContainer>
      {isTabletOrDesktop && (
        <>
          <Dot
            className="pointer"
            cursorData={cursorData}
            style={{ left: `${x}px`, top: `${y}px` }}
          ></Dot>
          <WrapperGoo
            cursorData={cursorData}
            style={{ left: `${x}px`, top: `${y}px` }}
          >
            <Goo cursorData={cursorData}></Goo>
            <Goo cursorData={cursorData}></Goo>
            <Goo cursorData={cursorData}></Goo>
            <Goo cursorData={cursorData}></Goo>
          </WrapperGoo>
          <StyledGoo>
            <defs>
              <filter id="filter">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="18"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 28 -10"
                  result="filter"
                />
                <feComposite in="SourceGraphic" in2="filter" operator="atop" />
              </filter>
            </defs>
          </StyledGoo>
        </>
      )}
    </PointerContainer>
  )
}

export default Pointer
