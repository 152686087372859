import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import MenuLink from "./menuLink"

import styled from "styled-components"
import ContactLink from "./contactLink"
import BurgerMenu from "./burgerMenu"

const MobileLinks = styled.ul`
  position: absolute;
  width: 100%;
  height: 0;
  padding: 0;
  visibility: hidden;
  height: ${props => (props.open ? "auto" : "0")};
  overflow-y: hidden;
  padding: ${props => (props.open ? "1rem" : "0")};
  visibility: ${props => (props.open ? "visible" : "hidden")};
  bottom: 74px;
  left: 0;
  background: var(--color-main);
  transition: all 0.5s ease-in-out;
  dislay: flex;
  gap: 0.5rem;
  flex-direction: column;
`

const MobileLink = styled.li`
margin: 0 !important;
text-align: center;
`

const MobileMenu = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allWpMenu {
        edges {
          node {
            id
            locations
            name
            menuItems {
              nodes {
                id
                label
                uri
              }
            }
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  const headerMenu = data.allWpMenu.edges.filter(function (edge) {
    return edge.node.locations.includes("GATSBY_HEADER_MENU")
  })[0]

  return (
    <>
      <BurgerMenu setOpen={setOpen} open={open} />
      <MobileLinks
        key={headerMenu.node.id}
        id={headerMenu.node.name + "-mobile"}
        open={open}
      >
        {headerMenu.node.menuItems.nodes.map(node => {
          return (
            <MobileLink onClick={() => setOpen(false)} key={node.id} className="main-menu__item">
              <MenuLink to={node.uri}>{node.label}</MenuLink>
            </MobileLink>
          )
        })}
        <MobileLink onClick={() => setOpen(false)}>
          <MenuLink to="/">Start</MenuLink>
        </MobileLink>
        <MobileLink onClick={() => setOpen(false)}>
          <MenuLink to="/blog">Blog</MenuLink>
        </MobileLink>
        <MobileLink onClick={() => setOpen(false)}>
          <MenuLink to="/referenzen">Referenzen</MenuLink>
        </MobileLink>
        <MobileLink onClick={() => setOpen(false)}>
          <MenuLink to="/workflow">Workflow</MenuLink>
        </MobileLink>
        <MobileLink onClick={() => setOpen(false)}>
          <MenuLink to="/kontakt">Kontakt</MenuLink>
        </MobileLink>
      </MobileLinks>
      <ContactLink fill="#fff" />
    </>
  )
}

export default MobileMenu
