import React, { useContext, useCallback } from "react"
import styled, { keyframes, css } from "styled-components"
import { ContactContext } from "../context/contact-context"
import Breakpoints from "../utils/breakpoints"
import LetterCloseSVG from "../svg/letter-close.svg"
import Form from "./form"

const fadeInAnimation = () => keyframes`
0%{
  opacity: 0;
}
100%{
  opacity: 1;
}
`

const FormWrapper = styled.div`
  height: 100%;
  width: 100%;
  @media (max-width: ${Breakpoints.mobile}px) {
    padding-top: 1rem;
  }
  ${props =>
    props.modal &&
    css`
      position: fixed;
      width: 0;
      height: 0;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background: #000;
      padding: 0 !important;
      overflow: hidden;
      transition: unset;
      border: 4px solid var(--color-main);
      visibility: hidden;
      z-index: 3;
      @media (min-width: ${+Breakpoints.mobile + 1}px) {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `};
  ${props =>
    props.modal &&
    props.modalState &&
    css`
      width: 90%;
      height: auto;
      overflow: visible;
      animation: ${fadeInAnimation} 1s ease-in-out;
      transition: all 1s ease-in-out;
      visibility: visible;
    `};
`

const FormInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  @media (max-width: ${Breakpoints.mobile}px) {
    flex-direction: column;
  }
  ${props =>
    props.modal &&
    css`
      @media (max-width: ${Breakpoints.mobile}px) {
        max-height: 70vh;
        overflow-y: scroll;
        justify-content: unset;
      }
    `};
`

const ContactInfo = styled.div`
  @media (min-width: ${+Breakpoints.mobile + 1}px) {
    width: 47ch;
    margin-right: 4rem;
  }
  p {
    font-size: 20px;
    line-height: 25px;
  }
  color: #fff;
`

const StyledLetterCloseSVG = styled(LetterCloseSVG)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  height: 40px;
  @media (max-width: ${Breakpoints.mobile}px) {
    transform: translate(40%, -40%);
    height: 30px;
  }
  background: #fff;
  cursor: pointer;
  path {
    fill: var(--color-main);
  }
`

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
`

const ContactForm = ({ position }) => {
  const { contactModalChangeHandler, contactModalOpen } = useContext(
    ContactContext
  )

  const handleClick = useCallback(() => {
    contactModalChangeHandler(!contactModalOpen)
  }, [contactModalOpen])

  const handleOutsideClick = useCallback(() => {
    contactModalChangeHandler(false)
  }, [contactModalOpen])

  return (
    <>
      {position === "modal" && contactModalOpen && (
        <ModalBackground
          modalState={contactModalOpen}
          onMouseDown={handleOutsideClick}
        />
      )}
      <FormWrapper
        id="contact-modal"
        modalState={contactModalOpen}
        modal={position === "modal" ? true : false}
      >
        <FormInner modal={position === "modal" ? true : false}>
          <ContactInfo>
            <h2 style={{ color: "#fff" }}>Kontakt</h2>
            <p>
              Sollten Sie Fragen haben oder ein unverbindliches Angebot
              wünschen, stehe ich Ihnen gerne zur Verfügung. Bitte zögern Sie
              nicht, mir eine Nachricht zu senden.
            </p>
          </ContactInfo>
          <Form />
          {/* <Form method="post" action={process.env.GATSBY_API_URL}>
            <Input type="text" name="name" />
            <Input type="email" name="email" />
            <Input type="textarea" name="message" />
            <Input type="submit" name="submit" value="Anfrage Abschicken" />
          </Form> */}
          {position === "modal" && contactModalOpen && (
            <StyledLetterCloseSVG onMouseDown={handleClick} />
          )}
        </FormInner>
      </FormWrapper>
    </>
  )
}

export default ContactForm
