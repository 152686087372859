// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

import React from "react"
import { AnimatePresence } from "framer-motion"

import MouseContextProvider from "./src/context/mouse-context"
import ContactContextProvider from "./src/context/contact-context"
import Pointer from "./src/components/pointer"
import Layout from "./src/components/layout"

export const wrapRootElement = ({ element }) => {
  return (
    <MouseContextProvider>
      <ContactContextProvider>{element}</ContactContextProvider>
    </MouseContextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Pointer />
      <AnimatePresence exitBeforeEnter>
        <Layout {...props}>{element}</Layout>
      </AnimatePresence>
    </>
  )
}

// export const onInitialClientRender = () => {
//   document.getElementById("___loader").style.display = "none"
// }

export const shouldUpdateScroll = ({
  routerProps: { location },

  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms

  // * 2 for exit + enter animation

  const TRANSITION_DELAY = 0.3 * 1000 * 2

  // if it's a "normal" route

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }

  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]

    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }

  return false
}
