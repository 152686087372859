import React from "react"
import styled, { css } from "styled-components"
import Breakpoints from "../utils/breakpoints"

const StyledInputWrapper = styled.div`
  color: #fff;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  label,
  input,
  textarea {
    width: 100%;
  }
`

const StyledInput = styled.input`
  height: 2rem;
  display: block;
  color: #fff;
  transition: all 0.5s ease-in-out;
  ${props =>
    props.type === "submit" &&
    css`
      @media (max-width: ${Breakpoints.mobile}px) {
        font-size: 0.7em;
        letter-spacing: 1px;
      }
      margin-top: 1.5em;
      cursor: pointer;
      background: var(--color-main);
      border: var(--color-main) 1px solid;
      color: #fff;
      padding: 0.5em 0;
      height: unset;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 3px;
      &:hover {
        background: var(--color-second);
        border: var(--color-second) 1px solid;
      }
    `};
  ${props =>
    props.type !== "submit" &&
    css`
      padding: 0 0.5em;
      background: var(--color-main);
      border: var(--color-main) 1px solid;
    `};
`
const StyledTextare = styled.textarea`
  height: 9em;
  display: block;
  background: var(--color-main);
  border: var(--color-main) 1px solid;
  color: #fff;
  padding: 0.5em;
`

const Input = props => {
  return (
    <StyledInputWrapper>
      <label htmlFor={props.name}>
        {props.type !== "submit" &&
          props.name.charAt(0).toUpperCase() + props.name.slice(1)}
        {props.type === "textarea" && (
          <StyledTextare
            name={props.name}
            type={props.type}
            onChange={props.onChange}
            value={props.value}
            required={props.required ? "required" : false}
          ></StyledTextare>
        )}
        {props.type !== "textarea" && (
          <StyledInput
            name={props.name}
            type={props.type}
            value={props.value}
            onChange={props.onChange}
            required={props.required ? "required" : false}
          />
        )}
      </label>
    </StyledInputWrapper>
  )
}

export default Input
