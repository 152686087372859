import React, { createContext, useState } from "react"

export const ContactContext = createContext({
  contactModalOpen: false,
  contactModalChangeHandler: () => {},
})

const ContactContextProvider = props => {
  const [contactModalOpen, setContactModalOpen] = useState(false)

  const contactModalChangeHandler = contactModalOpen => {
    setContactModalOpen(contactModalOpen)
    document.body.style.overflow = contactModalOpen ? "hidden" : "unset"
    document.body.style.position = contactModalOpen ? "fixed" : "unset"
  }

  return (
    <ContactContext.Provider
      value={{
        contactModalOpen: contactModalOpen,
        contactModalChangeHandler: contactModalChangeHandler,
      }}
    >
      {props.children}
    </ContactContext.Provider>
  )
}

export default ContactContextProvider
