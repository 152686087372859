import React from "react"

import styled, { css } from "styled-components"

const Burger = styled.div`
  cursor: pointer;
  width: 60px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease-out;
  > div {
    background: #fff;
  }
  ${props =>
    props.open &&
    css`
      transform: rotate(180deg);
      > div:nth-child(1) {
        transform: rotate(45deg) translate(14px, 12px);
      }
      > div:nth-child(2) {
        opacity: 0;
      }
      > div:nth-child(3) {
        transform: rotate(-45deg) translate(14px, -7px);
      }
    `}
  ${props =>
    props.fill &&
    css`
      &:hover > div {
        background: ${props => (props.hover ? props.hover : props.fill)};
      }
      > div {
        background: ${props=>props.fill};
      }
    `}
`

const BurgerLine = styled.div`
  width: 100%;
  height: 6px;
  transition: all 0.5s ease-out;
`

const BurgerMenu = ({ setOpen, open, fill, hover }) => {
  return (
    <Burger
      onClick={() => setOpen(!open)}
      open={open}
      tabindex="0"
      aria-label="Menu"
      role="button"
      aria-controls="navigation"
      fill={fill}
      hover={hover}
    >
      <BurgerLine fill={fill} hover={hover} />
      <BurgerLine fill={fill} hover={hover} />
      <BurgerLine fill={fill} hover={hover} />
    </Burger>
  )
}

export default BurgerMenu
