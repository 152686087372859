import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import { motionContainer } from "../utils/motion"

import GlobalStyle from "../utils/globalstyles"
import Header from "./header"
import Footer from "./footer"

import TopButton from "../components/topButton"
import ContactForm from "./contactForm"

const Layout = ({ children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" >
      <GlobalStyle />
      <Header title={title} />
      <motion.main
        variants={motionContainer}
        initial="hidden"
        animate="visible"
      >
        {children}
      </motion.main>

      <Footer />
      <TopButton />
      <ContactForm position="modal" />
    </div>
  )
}

export default Layout
